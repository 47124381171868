<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <filter-policies ref="FilterPolicies" @update="totalRecords = $event"/>
            </b-card>
        </b-col>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-row>
                        <b-col cols="12" md="9"
                               class="d-flex align-items-center justify-content-start mb-1"
                        >
                            <label>Mostrar</label>
                            <v-select
                                v-model="pageLength"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="['3','5','10','20', '50']"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="changeLength"
                            />
                            <create-policy-button/>
                        </b-col>
                    </b-row>

                    <table-policies ref="tablePolicies"/>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                cols="12"
                                sm="6"
                            >
                                <span class="text-muted">Mostrant {{ pageLength }} de {{ totalRecords }} pòlissas</span>
                            </b-col>

                            <b-col
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                cols="12"
                                sm="6"
                            >
                                <b-pagination
                                    :per-page="pageLength"
                                    :total-rows="totalRecords"
                                    :value="1"
                                    align="right"
                                    class="mt-1 mb-0"
                                    first-number
                                    last-number
                                    next-class="next-item"
                                    prev-class="prev-item"
                                    @input="changePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BPagination, BOverlay } from 'bootstrap-vue'
import TablePolicies from "@/views/insurance/policies/list/components/tablePolicies"
import CreatePolicyButton from "@/views/insurance/policies/list/components/createPolicyButton"
import {createHelpers} from "vuex-map-fields"
import {policiesStatus} from "@/constants/insurancePolicies"
import {policiesPeriods} from "@/constants/insurancePolicies"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import vSelect from "vue-select"
import FilterPolicies from "@/views/insurance/policies/list/components/filterPoliciesView"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ListPolicies",
    components: {
        BRow,
        BCol,
        BCard,
        BPagination,
        BOverlay,

        TablePolicies,
        CreatePolicyButton,
        FilterPolicies,
        vSelect
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: '',
            showLoading: false,

            policiesStatus,
            policiesPeriods,

            CatalanLocale
        }
    },
    mounted() {
        this.$refs.FilterPolicies.getPolicies()
    },
    methods: {
        changePage(page) {
            this.page = page
            this.$refs.FilterPolicies.page = page
            this.$refs.FilterPolicies.getPolicies()
        },
        changeLength(length) {
            this.$refs.FilterPolicies.pageLength = length
            this.$refs.FilterPolicies.getPolicies()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-anulled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="anulled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>